.Loading {
    width: 30px;
    height: 30px;
}

.Loading .spinner {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 3px solid #ccc;
    border-top-color: var(--main-color-3);
    animation: spin 0.6s linear infinite, color 2s ease-in-out infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

@keyframes blink {
    50% {
        opacity: 0.1;
    }
}

@keyframes color {
    50% {
        border-top-color: var(--main-color-2);
    }
}