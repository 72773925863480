@import "../../styles/index.css";

.ProjectHero {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
}

@media (max-width: 1024px) {
    .ProjectHero {
        flex-direction: column;
    }
}

.ProjectHero.right {
    flex-direction: row-reverse;
}

@media (max-width: 1024px) {
    .ProjectHero.right {
        flex-direction: column;
    }
}

.ProjectHero__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    width: 52%;
    padding: 0 3%;
}

@media (max-width: 1024px) {
    .ProjectHero__container {
        width: 100%;
    }
}

.ProjectHero__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 5% 10%;

    white-space: pre-line;
}

.ProjectHero__logo {
    width: 8%;
    height: auto;
    margin-bottom: 10px;

    opacity: 0.8;
}

@media (max-width: 1024px) {
    .ProjectHero__logo {
        width: 15%;
    }
}

.ProjectHero__skills {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 3%;

    align-self: flex-end;
}

@media (max-width: 1024px) {
    .ProjectHero__skills {
        align-self: center;
    }
}

@media (max-width: 1024px) {
    .ProjectHero__skills {
        flex-direction: column
    }
}

.ProjectHero__skills > * {
    margin: 0 10px;
}

.ProjectHero__skills > *:not(h3) {
    max-width: 60px;
    width: 100%;
}

@media (max-width: 1024px) {
    .ProjectHero__skills > * {
        margin: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.ProjectHero__skills h3 {
    font-size: 1.3rem;
    font-weight: 600;
    margin: 0;
    white-space: nowrap;
}


.ProjectHero__image {
    flex: 1;
    opacity: 1;

    width: 48%;

    object-fit: cover;
    object-position: center;
}

@media (max-width: 1024px) {
    .ProjectHero__image {
        width: 100%;
    }
}

.ProjectHero.color-2 {
    background-color: var(--main-color-2-transparent);
}

.ProjectHero.color-3 h3 {
    color: var(--main-color-2);
}

.ProjectHero.color-3 {
    background-color: var(--main-color-3-transparent);
}

.ProjectHero.color-2 h3 {
    color: var(--main-color-3);
}