@import "../../styles/index.css";


.Introduction {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    padding: 50px var(--main-padding);
}

@media (max-width: 1200px) {
    .Introduction {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

@media (min-width: 1400px) {
    .Introduction {
        padding: 50px 10%;
    }
}

.Introduction .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    animation: 2s ease-in-out 0s 1 slideInFromLeft;

    width: 70%;
}

@media (max-width: 1024px) {
    .Introduction .content {
        align-items: center;
    }
}

.Introduction svg {
    height: auto;

    width: 30%;
}

@media (max-width: 1024px) {
    .Introduction svg {
        width: 50%;
    }
}

.Introduction h1 {
    margin-bottom: 50px;

    font-size: 4rem;
}

@media (max-width: 1024px) {
    .Introduction h1 {
        font-size: 2.5rem;
    }
}

.Introduction .navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-top: 30px;

    width: 100%;
}

.Introduction .navigation > * {
    width: 100%;
    margin: 0 2%;
}

@media (max-width: 1024px) {
    .Introduction .navigation {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 10px;

        justify-items: center;
        align-items: center;

        margin-top: 30px;
    }

    .Introduction .navigation > * {
        margin: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        text-decoration: none;
    }
}

.Introduction .links {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    margin: 10%;
    width: 80%;
}

@media (max-width: 1024px) {
    .Introduction .links {
        margin: 30px 0;
        width: 100%;
    }
}

.Introduction .links > * {
    margin: 0 10px;
    max-width: 50px;
}



.Introduction .me {
    background-color: var(--main-bg-color-light);
    border-radius: 10%;

    animation: 2s ease-in-out 0s 1 slideInFromRight;
}

@media (min-width: 1200px) {
    .Introduction .me {
        height: 100%;
    }
}


@keyframes slideInFromLeft {
    0% {
        transform: translateX(-50%);
        opacity: 0;
    }
    50% {
        opacity: 0.1;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideInFromRight {
    0% {
        transform: translateX(50%);
        opacity: 0;
    }
    50% {
        opacity: 0.1;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.photo-portfolio-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    margin-bottom: 50px;
}

.photo-portfolio-section > a {
    text-decoration: none;
    color: var(--main-bg-color-dark);
    border: 1px solid var(--main-bg-color-dark);
    border-radius: 5px;
    padding: 10px;

    transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
}

.App.dark .photo-portfolio-section > a {
    color: var(--main-bg-color-light);
    border: 1px solid var(--main-bg-color-light);
}

.photo-portfolio-section > a:hover {
    background-color: var(--main-bg-color-dark);
    color: var(--main-bg-color-light);
}

.App.dark .photo-portfolio-section > a:hover {
    background-color: var(--main-bg-color-light);
    color: var(--main-bg-color-dark);
}