@import "../../styles/index.css";

.MainContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: space-between;


    margin-right: var(--sidebar-width);
}

@media (max-width: 1024px) {
    .MainContent {
        margin-right: 0;
        margin-top: var(--sidebar-width);
    }
}


.MainContent > * {
    margin-bottom: 150px;
    width: 100%;
}