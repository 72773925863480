@import "../../styles/index.css";


.DarkModeToggle {
  width: 30px;
}

@media (max-width: 1024px) {
  .DarkModeToggle {
    width: 20px;
  }
}

.DarkModeToggle:hover {
  cursor: pointer;
}

.DarkModeToggle__icon {
  width: 100%;
  height: 100%;

  fill: var(--main-bg-color-light);

  transition: var(--dark-mode-transition);
}

.App.dark .DarkModeToggle__icon {
  fill: var(--main-bg-color-dark);
}