@import "../../styles/index.css";

.Sidebar {
    top: 0;
    right: 0;
    height: 100vh;
    position: fixed;

    width: var(--sidebar-width);
    padding: 20px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background-color: var(--main-bg-color-dark);

    transition: var(--dark-mode-transition);
}

@media (max-width: 1024px) {
    .Sidebar {
        flex-direction: row;
        height: var(--sidebar-width);
        width: 100vw;
        padding: 10px;
    }
}

.Sidebar__content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;

}

.Sidebar__content > a {
    text-decoration: none;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;

    color: var(--main-bg-color-light);
}

@media (max-width: 650px) {
    .Sidebar__content > a {
        display: none;
    }

    .Sidebar {
        justify-content: space-between;
    }

    .Sidebar__content {
        justify-content: flex-end;
        width: 100%;
    }
}

@media (max-width: 1024px) {
    .Sidebar {
        justify-content: start;
    }

    .Sidebar__content {
        flex-direction: row;
        width: 100%;
    }
}

.Sidebar__content > *:hover {
    transform: scale(1.2);
    transition: transform 0.2s ease-in-out;
}


.App.dark .Sidebar {
    background-color: var(--main-bg-color-light);
    color: var(--main-bg-color-dark);
}

.App.dark .Sidebar__content > a {
    color: var(--main-bg-color-dark);
}