@import "../../styles/index.css";

.IconButton {
    background-color: var(--main-color-3-transparent);
    padding: 15px;
    border-radius: 10px;
    border: none;
    font-size: 1.5rem;

    width: 100%;

    transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out, transform 0.25s ease-in-out;
}

@media (max-width: 1024px) {
    .IconButton {
        padding: 10px;
    }
}

.IconButton:hover {
    cursor: pointer;
    color: var(--main-bg-color-light);

    transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out, transform 0.25s ease-in-out;

    transform: scale(1.1);
}

.IconButton svg {
    height: 30px;
    width: auto;
    margin-left: 10px;
}

@media (max-width: 1024px) {

    .IconButton svg {
        height: 100%;
    }

}

.IconButton.color-2 {
    color: var(--main-color-2);
    background-color: var(--main-color-2-transparent)
}

.IconButton.color-3 {
    color: var(--main-color-3);
    background-color: var(--main-color-3-transparent)
}

.IconButton.color-2:hover {
    background-color: var(--main-color-2);
    color: var(--main-bg-color-light);
}

.IconButton.color-3:hover {
    background-color: var(--main-color-3);
    color: var(--main-bg-color-light);
}