@import "../../styles/index.css";


.Skill {
    display: flex;
    flex-direction: column;


    height: 100%;
    width: 100%;

    padding: 10%;

    border-radius: 5px;

    transition: var(--dark-mode-transition);
}


.Skill img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.Skill .tooltip {
    z-index: 1;
}

.Skill.color-2 {
    background-color: var(--main-color-2-transparent);
}

.Skill.color-3 {
    background-color: var(--main-color-3-transparent);
}

.Skill:hover {
    transform: translateY(-5px);
}