@import "../../styles/index.css";

.LinkButton {
    border-radius: 5px;
    border: none;

    transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out, transform 0.25s ease-in-out;

    width: 100%;
    height: auto;

    background-color: var(--main-bg-color-light);

    transform: scale(1);
}

.LinkButton:hover {
    cursor: pointer;

    transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out, transform 0.25s ease-in-out;
    transform: scale(1.1);
}

.LinkButton svg {
    width: 100%;
    height: 100%;
}

.LinkButton.color-2 {
    color: var(--main-color-2);
    fill: var(--main-color-2);
}

.LinkButton.color-2:hover {
    color: var(--main-bg-color-dark);
    fill: var(--main-bg-color-dark);
}

.LinkButton.color-3 {
    color: var(--main-color-3);
    fill: var(--main-color-3);
}

.LinkButton.color-3:hover {
    color: var(--main-bg-color-dark);
    fill: var(--main-bg-color-dark);
}

.App.dark .LinkButton {
    background-color: var(--main-bg-color-dark);
}

.App.dark .LinkButton:hover {
    color: var(--main-bg-color-light);
    fill: var(--main-bg-color-light);
}