.Projects {
    display: flex;
    flex-direction: column;

    text-align: center;
}

.Projects > h1 {
    margin-bottom: 50px;

    font-size: 3rem;
}

@media (max-width: 1024px) {
    .Projects > h1 {
        font-size: 2rem;
    }
}

.VerticalText__container {
    position: relative;

    margin-top: 50px;
    font-size: 1.5rem;
}