.Feed {
    display: flex;
    flex-direction: column;

    text-align: center;

    padding: 0px var(--main-padding);
}

.Feed > h1 {
    margin-bottom: 50px;

    font-size: 3rem;
}

@media (max-width: 1024px) {
    .Feed > h1 {
        font-size: 2rem;
    }
}

.Feed .feeds {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;

    width: 100%;
}

@media (max-width: 1024px) {
    .Feed .feeds {
        flex-direction: column;
        align-items: center;
    }
}

.Feed .feeds > * {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 50%;
}

@media (max-width: 1024px) {
    .Feed .feeds > * {
        width: 100%;
    }
}

.Feed .header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 100%;
    margin-bottom: 50px;

    max-height: 50px;
}

.Feed .header .LinkButton {
    margin-right: 30px;
    width: 50%;
    max-width: 50px;
}
