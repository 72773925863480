.Contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: var(--main-padding);
}

.Contact > h1 {
    font-size: 3rem;
}

@media (max-width: 1024px) {
    .Contact > h1 {
        font-size: 2rem;
    }
}

.Contact > h3 {
    margin-bottom: 30px;
}

.Contact a {
    color: var(--main-color-2);
    font-weight: 600;
}