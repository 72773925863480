@import url("./index.css");


.App {
    background-color: var(--main-bg-color-light);
    color: var(--main-bg-color-dark);

    transition: var(--dark-mode-transition);
}

.App.dark {
    background-color: var(--main-bg-color-dark);
    color: var(--main-bg-color-light);
}

.color-2 {
    color: var(--main-color-2);
}

.color-3 {
    color: var(--main-color-3);
}