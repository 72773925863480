@import "../../styles/index.css";


.ContactForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
}

@media (max-width: 768px) {
    .ContactForm {
        width: 100%;
    }
}

.ContactForm .section1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 1024px) {
  .ContactForm .section1 {
    flex-direction: column;
  }
}

.ContactForm .section2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.ContactForm label {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 10px 0;
}

.ContactForm input, textarea {
  width: 100%;
  height: 40px;
  margin: 10px 0;
  padding: 0 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
    background-color: var(--main-color-light-2);
}

.ContactForm textarea {
  height: 200px;
    margin-bottom: 20px;
}

.ContactForm .error {
    animation: shake 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

@keyframes shake {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(10px);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes opacity {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}


.ContactForm .success {
    color: var(--main-color-2);
    font-size: 1.2rem;
    margin-top: 20px;
    font-weight: 600;

    animation: fadein 0.5s ease-in-out, fadeout 0.5s ease-in-out 4.5s;
}

.ContactForm .mailError {
    color: #b60000;
    font-size: 1.2rem;
    margin-top: 20px;
    font-weight: 600;

    animation: fadein 0.5s ease-in-out, fadeout 0.5s ease-in-out 4.5s;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeout {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 0;
    }
}