@import "../../styles/index.css";


.LanguageSelector {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    padding: 5px;
    border-radius: 5px;

    background-color: var(--main-color-3-transparent);
}

.LanguageSelector img {
    width: 50px;
    height: auto;

    border-radius: 10%;

    opacity: 0.7;

    cursor: pointer;

    transition: opacity 0.2s ease-in-out;

    margin: 0px 10px;
}

@media (max-width: 1024px) {
    .LanguageSelector img {
        width: 30px;
    }
}

.LanguageSelector img:hover {
    opacity: 1;
}

.LanguageSelector img.active {
    opacity: 1;
}