@import "../../styles/index.css";

.About {
    display: flex;
    flex-direction: column;

    text-align: center;

    padding: 0px var(--main-padding);
}

.About > h1 {
    margin-bottom: 50px;

    font-size: 3rem;
}

@media (max-width: 1024px) {
    .About > h1 {
        font-size: 2rem;
    }
}

.About .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: 100%;

    font-size: 1.2rem;
    white-space: pre-line;
}

@media (max-width: 1024px) {
    .About .content {
        flex-direction: column;
    }
}

.About .content > * {
    width: 50%;
    margin: 0 50px;
}

@media (max-width: 1024px) {
    .About .content > * {
        width: 100%;
        margin: 0;
    }
}

.About .skills-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 1024px) {
    .About .skills-section {
        margin-top: 50px;
    }
}

.About .skills-section > * {
    margin-bottom: 50px;
}

.About .grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 20px;
}

@media (max-width: 1200px) {
    .About .grid {
        grid-template-columns: repeat(5, 1fr);
    }
}